/* ====================================
Default Class Styles across all pages
 using Tailwind Css Media Queries
 lg =  1024px
 xlg = 1280px
 md = 768px
======================================= */

:root {
    --btn-border: 1px solid #79747e;
    --dark-color: #0c1219;
    --frame-color:#999999;
    --text-black:#00140A;
    --black-color: #00140A;
    --blue-color:#065FD4;
    --grey-text:#808080;
    --grey-color:#EAE7E2;
    --grey-bg:#A9A9A9;
    --white-color: #ffffff;
    --white-bg: #ffffff;
    --primary-btn:#2c7e54;
    --primary-color:#2b6cb0;
    --light-blue:#1e90ff;
    --secondary-btn:#FFD700;
    --secondary-color:#FFD700;
    --footer-bg:#1A202C;
    --deep-blue:#1A202C;
    --light-grey:#E2E8F0;
    --red-color:#E53E3E;
    --teal-color:#319795;
    --green-color:#2C7E54;
    --disabled-color:#ABCBBB;
    /* font sizes */
    --xs-font:0.4rem; 
    --sm-font:0.875rem;
    --md-font:1rem;
    --lg-font:1.4rem;
    --xl-font:2.5rem;
   
  }
  
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
  *::selection {
    background: #3a8d97;
    color: #fff;
  }
  ::placeholder {
    font-size: 1rem;
  }
  a {
    text-decoration: none;
    display: block;
    padding: 0.7rem;
    text-transform: capitalize;
    font-size: 0.878rem;
    font-weight: 400;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: "Poppins",sans-serif;
    background:var(--white-color);
    color: var(--black-color);
    counter-reset: count;
    overflow-x: hidden;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-transform:capitalize;
    font-weight: 600;
    margin-bottom: 0.85rem;
    font-family: "Poppins",sans-serif;
  }
  h1 {
    font-size: 3rem;
    font-weight:800;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* line-height: 100%; */
    line-height:1.5;
  }
  h2 {
    font-size: 2.5rem;
    font-weight:600;
  }
  h3 {
    font-size: 2rem;
    /* color:var(--primary-color); */
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 1rem;
  }
  
  input,
  button {
    box-shadow: none;
    border: none;
    outline: none;
  }
  img {
    display: block;
    border-radius: inherit;
    width: 100%;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height:1.8;
  }
  section {
    padding: 1.5rem 5%;
  }
  section:not(:last-child){
  margin-bottom:4rem;
  }
  .cta-btn,
  button:not(.toastify-btn) {
    padding: 0.85rem 1.2rem;
    display: inline-block;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 0.875rem;
    /* font-family: "Sailec Medium"; */
    font-weight:600;
    background:var(--primary-btn);
    color: var(--white-color);
  }
  
  
  
  .btn-container {
    text-align: center;
  }
  .btn-container .cta-btn {
    display: inline-block;
    /* width: 120px; */
    border-radius:25px;
    padding: 12px 24px;
    color: var(--dark-color);
    cursor: pointer;
    text-transform: capitalize;
  }
  .cta-btn {
    text-align: center;
  }
  
  /* ============Form element Stykes========= */
  .form-container .double {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 4%;
  }
  .form-container .double > * {
    flex-basis: 48%;
  }
  .form-field {
    position: relative;
    border-radius: 5px;
    margin-bottom: 1.2rem;
  }
  .form-field label {
    text-transform: capitalize;
    margin-bottom: 0.45rem;
    font-weight: 600;
    display: block;
  }
  .form-field input,
  .form-field textarea,
  .form-field select{
    width: 100%;
    padding: 1rem;
    border-radius:12px;
    border:1px solid var(--frame-color);
    box-shadow: none;
    outline: none;
    font-size:1rem;
  }
  .form-field .select-field{
  position: relative;
  }
  .form-field .select-field::before{
   content: ">";
   position:absolute;
   display:inline-block;
   font-size:1.4rem;
   top:12px;
   right:12px;
   transform:rotate(90deg);
   cursor: pointer;
  }


  .form-field input:focus{
  border:2px solid var(--green-color);
  }
  .form-field select,
  input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
  }
  .form-field .eye-icon-container,
  .form-field .icon-container {
    position: absolute;
    top: 50%;
    transform: translate(0%, 10%);
    right: 15px;
    cursor: pointer;
    display: inline-block;
    /* width: 25px;
    height: 25px; */
    
  }
  .form-field .eye-icon-container.active svg path {
    stroke:var(--primary-btn);
  }
  
  /* ==============Message info============ */
  .form-container .error-message {
    color: #f00;
    font-size: 0.875rem;
  }
  /*============ Flex and Grid Items================ */
  .grid-container-2 > *,
  .grid-container-3 > *,
  .flex-container-2 > *,
  .flex-container-4 > * {
    margin-bottom: 1.5rem;
  }
  
  /* Custom font class */
  .sailec-medium {
    font-weight: 400;
    font-family: "Sailec Medium";
  }
  
  /* SPINNER STYLES */
  .spinner {
    animation: spin 1s linear infinite;
    display: inline-block;
  }
  
  .spin-container svg {
    width: 20px;
    height: 20px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* ==========================
  For Desktop Device
  =============================*/
  @media (min-width: 980px) {
    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: 3.5rem;
    }
    h3 {
      font-size: 3rem;
    }
    h4 {
      font-size: 1.75rem;
    }
    h5{
    font-size:1.5rem;
    }
    a,
    p {
      font-size: 1rem;
    }
    .cta-btn,
    button:not(.toastify-btn) {
      font-size: 1rem;
    }
    section {
      padding: 2rem 7%;
    }
   
    .flex-container-4 {
      align-items: flex-start;
    }
    .flex-container-4{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 4%;
    }
    .flex-container-4 > * {
      flex-basis: 22%;
    }
    .grid-container-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.4rem;
    }
    .grid-container-3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      /* place-items: center; */
    }
    /* .flex-container-2 > * {
      flex-basis: 48%;
    } */
    .flex-container-4 > *,
    .grid-container-3 > *,
    .grid-container-2 > * {
      margin-bottom: 0;
    }
  }
  
  /* ==========================
  Large Desktop Device
  ==============================*/
  @media (min-width: 1280px) {
    .flex-container-2 {
      display: flex;
      flex-flow: row wrap;
      /* align-items: center; */
      gap: 4%;
    }
    .flex-container-2 > * {
      flex-basis: 48%;
    }
    .flex-container-2 > *{
      margin-bottom: 0;
    }
  }
  